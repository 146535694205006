import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import {
  SD_divider,
  sd_minus_icon,
  sd_plus_icon,
  user12,
  user2,
  user3,
  user4,
  user5,
  user6,
  userborder,
  Temple_history,
  Saswatha,
  svtrust,
  intellectual,
  chairman,
  executive,
  user7,
  user9,
  user13,
  user15,
  SD_gallery_08,
  user10,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";
import Sd_loading from "../../sd-common-components/sd-loading";
import Sd_comming_soon from "../../../pages/sd-about/sd-coming-soon";

const Sd_about_general_information_component = ({
  match,
  visible,
  history,
}) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [type, setType] = useState(match.params.type);
  const [drop1, setDrop1] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [drop3, setDrop3] = useState(false);
  const [drop4, setDrop4] = useState(false);
  const [drop5, setDrop5] = useState(false);
  const [drop6, setDrop6] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const titleRef = useRef();
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (match?.params?.type) {
      setType(match?.params?.type);
    } else {
      setType("sri-anjaneya-swamy-temple-manikanta-eswara-swamy-temple");
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [match?.params?.type]);

  useEffect(() => {
    setImageLoading(true);

    if (data[type]?.image === "") {
      setImageLoading(false);
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [type]);

  useEffect(() => {
    history?.push(`/${selectedLanguage}/about/general-information/${type}`);
  }, [type]);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  const data = {
    "visiting-places": {
      heading: `Visiting Places`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",
      seo_tag: {
        title:
          "Places To Visit In Kanipakam | Kanipakam Visiting Places | Places Near Kanipakam",
        desc: "Places to visit in Kanipakam are innumerable as Kanipakam itself is a unique world of wonders. Places near Kanipakam are Akkamahadevi  caves, Mallela Theertham.",
        keywords:
          "Places To Visit In Kanipakam, Kanipakam Visiting Places, Places Near Kanipakam",
        h1_tag: "Places To Visit In Kanipakam",
      },
    },
    dieties: {
      heading: `Dieties`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",

      seo_tag: {
        title: "",
        desc: "",
        keywords: "",
        h1_tag: "",
      },
    },

    "sri-anjaneya-swamy-temple": {
      heading: `Sri Anjaneya Swamy Temple`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },
    "sri-maragadabika-manikanta-eswara-swamy-temple": {
      heading: `Sri Maragadabika Manikanta Eswara Swamy Temple`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },
    "sri-varada-raja-swamy-temple": {
      heading: `Sri Varada Raja Swamy Temple`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },

    trusts: {
      heading: `Trusts`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",
      seo_tag: {
        title:
          "Kaniapaka Devasthanam Trust | Kanipakam Trust | Kaniapaka Devasthanam",
        desc: "Kanipaka Devasthanam Trust comes under Kanipaka Devasthanam to collect funds for preserving the ancient shrine. Contribute to Kanipakam Trust.",
        keywords:
          "Kaniapaka Devasthanam Trust, Kanipakam Trust, Kaniapaka Devasthanam",
        h1_tag: "Kaniapaka Devasthanam Trust",
      },
    },
    "e-hundi": {
      heading: `E Hundi`,
      description1: (
        <>
          The holistic Idol of Sri Varasiddhi Vinayaka Swamy in the sacred well
          remains as a matchless place in the World. e-Hundi allows donations
          from people across the world for the development and welfare of the
          Kanipakam Devasthanam. The devotees can donate to Sri Kanipaka
          Devasthanam E-Hundi through online and gain the blessings of Sri
          Varasiddhi Vinayaka Swamy.
        </>
      ),
      image: Temple_history,
      seo_tag: {
        title:
          "Kaniapaka Devasthanam | Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
        desc: "Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam Trust is a nonprofitable organisation. People make donations to SBMSD Trust on Kanipaka Devasthanam Online.",
        keywords:
          "Kaniapaka Devasthanam, Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
        h1_tag: "Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
      },
    },
    "saswatha-nithya-annadanam-trust": {
      heading: `Saswatha Nithya Annadanam Trust`,
      description1: (
        <>
          The devotees anywhere from the world can serve the people who are in
          hunger by donating to Saswatha Nithya Annadanam Trust. The donor will
          become a Sevadari by donation to this Trust and can gain the divine
          blessings of Sri Varasiddhi Vinayaka Swamy whereas the Devasthanam
          recites the Sevadari Gothram and names. Even the Sevadari will get
          invited to annual Brahmotsavams at Kanipakam by minimum of Rs.
          10,116/- which is meant to be for Ten Years. The minimum amount to be
          donated for Nithya Annadanam Trust is Rs. 1,116/-. The donors can
          donate through our online website. The Nithya Annadanam Online
          Donations consists of the 80G Tax Exemption, the institution has got
          approval U/s 80G with effect from 24-09-2021 in Unique Registration
          Number : AABTS2001GF20217. The following are the privileges for the
          Donors by value of donations accordingly.
        </>
      ),
      image: Saswatha,
    },
    "sri-varasiddhi-vinayaka-go-samrakshana-trust": {
      heading: `Sri Varasiddhi Vinayaka Go Samrakshana Trust`,
      description1: (
        <>
          The devotees anywhere from the world can serve the Sacred Cow by
          donating to Sri Siddhi Vinayaka Gosamrakshana Trust. The donor will
          become a Sevadari by donation to this Trust and can gain the divine
          blessings of Cow whereas the Devasthanam performs Govu Pooja by
          reciting the Sevadari Gothram and names. Even the Sevadari will get
          invited to annual Brahmotsavams at Kanipakam by minimum of Rs.
          10,116/- which is meant to be for Ten Years. The minimum amount to be
          donated for Gosamrakshana Trust is Rs. 1,116/-. The donors can donate
          through our online website.
        </>
      ),
      image: svtrust,
    },

    "do's-&-don'ts": {
      heading: `Do's & Don'ts`,
      description1: (
        <>
          <b>Do’s in Kanipaka Devasthanam:</b> <br />
          - Maintain quietness, inside the Sanctum <br />
          - Chant “Om sri vigneswaraya Namah” in the queue <br />
          - Keep your center of attention on Swayambhu Sri Varasiddhi Vinayaka
          Swamy to attain peace of mind in the temple <br />
          - Leave your footwear outside temple <br />
          - Dispose off wastes in the recycle bins provided
          <br />
          - Vehicles must be parked at designated parking areas only <br />
          - Deposit your offerings only in the Hundi <br />
          - Keep Kumkuma on forehead <br />
          - Follow the procedures of the temple and wait for your turn for the
          Darshanam of the God <br />
          <br />
          <br />
          <b>Don’ts in Kanipaka Devasthanam: </b>
          <br />
          - Don’t Wear footwear, hats, sunglasses, caps and turbans inside the
          temple
          <br />
          - Taking photos and videos in the temple is prohibited <br />
          - Don’t bring electronics and gadgets inside the temple <br />
          - Don’t waste Prasadam given by Devasthanam <br />
          - Do not approach touts for any services of Devasthanam like
          Darshanam, Prasadam and Accommodation <br />
          - Do not encourage beggars at Kanipakam <br />
          - Don’t spit or create nuisance in the premises of the temple <br />
        </>
      ),
      image: "",
    },
    "intellectual-property": {
      heading: `Intellectual Property`,
      description1: (
        <>
          The materials on this site and any other World Wide Web site/Mobile
          Application owned, operated, licensed or controlled by Kanipaka
          Devasthanam or any of its related, affiliated or subsidiary companies,
          may not be copied, distributed, republished, uploaded, posted, linked
          or transmitted in any way without the prior written consent of
          Kanipaka Devasthanam. <br />
          All material(Logos, trademarks, GI, etc) are protected under
          copyright, trademark and other intellectual property laws.
          Modification or use of the materials for any other purpose than
          personal/non-commercial use violates Kanipaka Devasthanam’s
          intellectual property rights.
        </>
      ),
      image: intellectual,
    },
    certifications: {
      heading: `Certifications`,
      description1: (
        <>
          Kanipaka Devasthanam complies in accordance with aagamas or Yogic way
          of providing spiritual assistance. The quality services, environmental
          friendly affairs, security and strict adherence to rituals qualify for
          certifications
        </>
      ),
      image: SD_gallery_08,
    },

    // chairman: {
    //   heading: `A Word With You`,
    //   description1: (
    //     <div style={{ display: "flex" }} className="general-flex">
    //       <div style={{ textAlign: "center" }}>
    //         <img src={chairman} height="200px" />
    //         <h4
    //           style={{
    //             padding: "8px 0px",
    //             color: "#991403",
    //             fontWeight: "600",
    //           }}
    //         >
    //           Mohan reddy
    //         </h4>
    //         <h5 style={{ marginBottom: "5px" }}>Chairman</h5>
    //         <h5>Board of Trustees</h5>
    //       </div>
    //       <div style={{ paddingLeft: "20px" }}>
    //         <h3 style={{ fontStyle: "italic" }}>Namaskaram…</h3>
    //         <br />
    //         As more than 3 to 4 thousand devotees on average days and 7 to 10
    //         thousand on peak days like Sundays, Mondays etc. On average they
    //         visit the holy shrine of Kanipakam. The Devasthanam organizes
    //         efficient systems in providing various facilities to pilgrims. The
    //         aim of the Devasthanam is always improving these amenities.
    //         <br />
    //         <br />
    //         To propagate the Hindu Dharma and Bhakti Cult among the public and
    //         devotees in devotees, the Devathanam has taken up various Dharma
    //         Pracharam Programmes.
    //         <br />
    //         <br />
    //         The major amenities for devotees are RO Water supply, Que- complex,
    //         Dormitories, Choultries, Cottages for accommodation, etc. The
    //         Devasthanam requests the Devotees to come forward and make liberal
    //         donations for divine causes and obtain the blessings of Swayambhu
    //         Sri Varasiddhi Vinayaka Swamy. The donations can be made through
    //         Cheque/Demand Draft in favor of The Executive Officer, Swayambhu Sri
    //         Varasiddhi Vinayaka Swamy vari Devasthanam, Kanipakam. The donations
    //         can be made through online also. The donations given to this
    //         Devasthanam are exempt from the Income tax under section 80 G of the
    //         Income Tax Act- 1962.
    //         <br />
    //         <br />
    //         May Sri Varasiddhi Vinayaka Swamy of Sri Kanipakam showers their
    //         choicest blessings for your long life.
    //         <br />
    //         <br />
    //         <b>
    //           Chairman <br /> Board of trustees
    //         </b>
    //       </div>
    //     </div>
    //   ),
    //   image: "",
    // },
    executive: {
      heading: `A Word With Devotee`,
      description1: (
        <div style={{ display: "flex" }} className="general-flex">
          <div style={{ textAlign: "center" }}>
            <img src={executive} height="200px" />
            <h4
              style={{
                padding: "8px 0px",
                color: "#991403",
                fontWeight: "600",
              }}
            >
              K. Penchala Kishore
            </h4>
            <h5> Executive Officer </h5>
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <h3 style={{ fontStyle: "italic" }}>Namaskaram…</h3>
            <br />
            Kanipakam is one of the ancient temples of Andhra Pradesh with
            sacred water well surrounding the Main Idol of Sri Varasiddhi
            Vinayaka Swamy which was a self manifestation. Everyday thousands of
            devotees visit Kanipakam and make the divine Darshanam of Swayabhu
            Sri Varasiddhi Vinayaka Swamy for the holy blessings. The
            Devasthanam in the promulgation of Hindu Dharma and Bhakti Cult
            amongst the Public, various Dharma Pracharam programs are being run
            in various regions.
            <br />
            <br />
            For the improvements in amenities at the Temple and surrounding
            premises, devotees are requested to donate for the divine cause
            through Cheque/Demand Draft in favor of The Executive Officer,
            Swayambhu Sri Varasiddhi Vinayaka Swamy vari Devasthanam, Kanipakam.
            Right now, the major amenities for devotees are RO Water supply,
            Que- complex, Dormitories, Choultries, Cottages for accommodation,
            etc. The donations can be made online through our website
            www.srikanipakadevasthanam.org.
            <br />
            <br />
            The donations given to this Devasthanam are exempt from the Income
            tax under section 80 G of the Income Tax Act- 1962.
            <br />
            <br />
            May Sri Varasiddhi Vinayaka Swamy showers the blessings for the
            devotees for a healthy and wealthy life.
            <br />
            <br />
            <b>The Executive Officer</b>
          </div>
        </div>
      ),
      image: "",
    },
    "trust-board": <Sd_comming_soon />,

    // "trust-board": {
    //   heading: `Board of Trustees`,
    //   description1: (
    //     <div className="trust-board-wrapper">
    //       <div style={{ width: "100%" }}>
    //         <div className="profile-pic" style={{ textAlign: "center" }}>
    //           <img src={chairman} height="200px" />
    //           <h4
    //             style={{
    //               padding: "8px 0px",
    //               color: "#991403",
    //               fontWeight: "600",
    //             }}
    //           >
    //             Mohan reddy
    //           </h4>
    //           <h5 style={{ marginBottom: "5px" }}>Chairman</h5>
    //         </div>
    //       </div>
    //       <div className="flexed">
    //         {[
    //           { image: user2, name: "K Gopi", position: "Member" },
    //           { image: user3, name: "P Govardhan", position: "Member" },
    //           { image: user4, name: "V Susila", position: "Member" },
    //           { image: user5, name: "D Kanthamma", position: "Member" },
    //           {
    //             image: user6,
    //             name: "M Narasimhulu Chetty",
    //             position: "Member",
    //           },
    //           { image: user7, name: "A Prathima", position: "Member" },
    //           { image: user9, name: "K. Raveendrudu", position: "Member" },
    //           // { image: user9, name: "K Marutheswar Rao", position: "Member" },
    //           {
    //             image: userborder,
    //             name: "A Subbalakshumma",
    //             position: "Member",
    //           },
    //           { image: userborder, name: "G Kondaiah", position: "Member" },
    //           {
    //             image: user12,
    //             name: "Smt G Venkata Ramanamma",
    //             position: "Member",
    //           },
    //           { image: user13, name: "J Ramsubbamma", position: "Member" },
    //           { image: userborder, name: "B Anitha", position: "Member" },
    //           { image: user10, name: "M Jayasri,", position: "Member" },
    //           {
    //             image: user15,
    //             name: "Sri N Somasekhar, Upa Pradhana Archaka Ex-Officio",
    //             position: "Member",
    //           },
    //         ].map((item, i) => {
    //           return (
    //             <div key={"board" + i} className="trust-board">
    //               <img src={item?.image} height="200px" />
    //               <h4
    //                 style={{
    //                   padding: "8px 0px",
    //                   color: "#991403",
    //                   fontWeight: "600",
    //                 }}
    //               >
    //                 {item.name}
    //               </h4>
    //               <h5> {item.position}</h5>
    //             </div>
    //           );
    //         })}
    //       </div>
    //     </div>
    //   ),
    //   image: "",
    // },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          {data[type]?.seo_tag?.title ??
            "Kanipaka Devasthanam Official Website"}
        </title>
        <meta
          name="description"
          content={
            data[type]?.seo_tag?.desc ?? "Kanipaka Devasthanam Official Website"
          }
        />
        <meta name="keywords" content={data[type]?.seo_tag?.keywords} />
      </Helmet>
      <div className="sd-about-temple-history">
        {visible && (
          <div className="sd-about-temple-history-left">
            <ol>
              <li
                className={type === "visiting-places" ? "sd-active" : ""}
                onClick={() => {
                  setDrop6(false);
                  setDrop1(!drop1);
                }}
              >
                Visiting Places
                {drop1 ? (
                  <img
                    height="18px"
                    src={sd_minus_icon}
                    alt="-"
                    style={{ marginTop: "6px" }}
                    onClick={() => {
                      setDrop1(false);
                    }}
                  ></img>
                ) : (
                  <img
                    height="18px"
                    style={{ marginTop: "6px" }}
                    src={sd_plus_icon}
                    onClick={() => {
                      setDrop1(true);
                    }}
                  />
                )}
              </li>
              <div className={drop1 ? "drop1 block" : "drop1 none"}>
                <div
                  className={type === "dieties" ? "main sd-active" : "main"}
                  onClick={() => {
                    setDrop2(!drop2);
                    // setType("dieties");
                  }}
                >
                  <span>Dieties</span>
                  {drop2 ? (
                    <img
                      height="18px"
                      src={sd_minus_icon}
                      alt="-"
                      style={{ marginTop: "6px" }}
                      onClick={() => {
                        setDrop2(false);
                      }}
                    ></img>
                  ) : (
                    <img
                      height="18px"
                      style={{ marginTop: "6px" }}
                      src={sd_plus_icon}
                      onClick={() => {
                        setDrop2(true);
                      }}
                    />
                  )}
                </div>
                <div className={drop2 ? "drop2 block" : "drop2 none"}>
                  <div
                    className={
                      type === "sri-anjaneya-swamy-temple" && "sd-active"
                    }
                    onClick={() => {
                      setType("sri-anjaneya-swamy-temple");
                    }}
                  >
                    Sri Anjaneya Swamy Temple
                  </div>
                  <div
                    className={
                      type ===
                        "sri-maragadabika-manikanta-eswara-swamy-temple" &&
                      "sd-active"
                    }
                    onClick={() => {
                      setType("sri-maragadabika-manikanta-eswara-swamy-temple");
                    }}
                  >
                    Sri Maragadabika Manikanta Eswara Swamy Temple{" "}
                  </div>
                  <div
                    className={
                      type === "sri-varada-raja-swamy-temple" && "sd-active"
                    }
                    onClick={() => {
                      setType("sri-varada-raja-swamy-temple");
                    }}
                  >
                    Sri Varada Raja Swamy Temple
                  </div>
                </div>
              </div>

              <li
                className={type === "trusts" ? "sd-active" : ""}
                onClick={() => {
                  setDrop6(!drop6);
                }}
              >
                <span> Trusts</span>{" "}
                {drop6 ? (
                  <img
                    height="18px"
                    src={sd_minus_icon}
                    alt="-"
                    style={{ marginTop: "6px" }}
                    onClick={() => {
                      setDrop6(false);
                    }}
                  ></img>
                ) : (
                  <img
                    height="18px"
                    style={{ marginTop: "6px" }}
                    src={sd_plus_icon}
                    onClick={() => {
                      setDrop6(true);
                    }}
                  />
                )}
              </li>

              <div className={drop6 ? "drop6 block" : "drop6 none"}>
                <div
                  className={type === "e-hundi" && "sd-active"}
                  onClick={() => {
                    setType("e-hundi");
                  }}
                >
                  e-Hundi
                </div>
                <div
                  className={
                    type === "saswatha-nithya-annadanam-trust" && "sd-active"
                  }
                  onClick={() => {
                    setType("saswatha-nithya-annadanam-trust");
                  }}
                >
                  Saswatha Nithya Annadanam Trust
                </div>
                <div
                  className={
                    type === "sri-varasiddhi-vinayaka-go-samrakshana-trust" &&
                    "sd-active"
                  }
                  onClick={() => {
                    setType("sri-varasiddhi-vinayaka-go-samrakshana-trust");
                  }}
                >
                  Sri Varasiddhi Vinayaka Go Samrakshana Trust
                </div>
              </div>
              {/* <li
                className={type === "trust-board" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("trust-board");
                }}
              >
                Trust's Board{" "}
              </li>
              <li
                className={type === "chairman" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("chairman");
                }}
              >
                Chairman
              </li> */}
              <li
                className={type === "executive" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("executive");
                }}
              >
                Executive Officer
              </li>
              <li
                className={type === "do's-&-don'ts" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("do's-&-don'ts");
                }}
              >
                Do's & Don'ts{" "}
              </li>
              <li
                className={type === "intellectual-property" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("intellectual-property");
                }}
              >
                Intellectual Property{" "}
              </li>
              <li
                className={type === "certifications" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("certifications");
                }}
              >
                Certifications{" "}
              </li>
            </ol>
          </div>
        )}{" "}
        <div className="sd-about-temple-history-right">
          <div style={{ display: imageLoading ? "none" : "block" }}>
            <div className="sd-about-temple-history-right-top" ref={titleRef}>
              <h1>
                <span> {data[type]?.heading}</span> {}
              </h1>
              <img
                src={SD_divider}
                style={{ height: "20px" }}
                alt={"divider"}
              ></img>
            </div>
            <div className="sd-about-temple-history-right-bottom">
              <div className="sd-athrb-top">
                {data[type]?.image !== "" ? (
                  <img
                    src={data[type]?.image}
                    alt="alternative"
                    align="left"
                    className="sd-imgs"
                    onLoadStart={() => {
                      setImageLoading(true);
                    }}
                    onLoad={() => {
                      setImageLoading(false);
                    }}
                  />
                ) : (
                  ""
                )}
                <p>{data[type]?.description1}</p>
              </div>
              {/* <div className="sd-athrb-bottom">{data[type].description2}</div> */}
            </div>
          </div>
          <div
            style={{
              display: !imageLoading ? "none" : "block",
              height: "30%",
            }}
          >
            <Sd_loading />
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Sd_about_general_information_component);
